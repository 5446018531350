body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

p,
span,
div,
* {
  font-size: 12px;
  /* font-size: 0.9rem; */
}

a {
  outline: none;
}

*::-webkit-scrollbar {
  height: 0.8em;
  width: 0.4em;
}

*::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); */
  /* background-color: #EEEEEE; */
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  /* outline: 1px solid slategrey; */
  border-radius: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-size: 14px; */
}

.w-33 {
  width: 33.33333333%;
}

canvas {
  width: 100% !important;
  height: 100% !important;
  /* min-height: 400px; */
}

.chart_wrap_md {
  min-height: 360px;
}

.chart_wrap_md {
  /* min-height: 420px; */
}

.tooltip-auto-width .tooltip-inner {
  max-width: 100%;
}

.tooltip-auto-width .tooltip-inner {
  width: auto;
  white-space: normal;
}

.tooltip-row span {
  max-width: 380px;
  text-align: left;
}

.circle-status {
  display: block;
  border-radius: 50%;
  background-color: #999999;
  height: 7px;
  width: 7px;
}

.btn-light {
  /* background-color: #f6f7fb; */
  background-color: #f1f1f1 !important;
}

.btn-square {
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
}

/* topbar */

.topbar-appswitcher span {
  font-size: 10px;
}

.topbar-appswitcher a {
  transition: .3s;
}

.topbar-appswitcher a:hover {
  background-color: #F6F7FB;
}

.breadcrumb-wrap {}

.breadcrumb-wrap a {
  text-decoration: none;
  color: #999999;
}

.breadcrumb-wrap a:hover {
  color: #F9A825;
}

.breadcrumb-wrap a span {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}

.breadcrumb-wrap a:last-of-type {
  color: #000000;
}

.breadcrumb-wrap a:last-of-type span {
  font-weight: 500;
}


/* Sidebar */
.sidebar {
  /* width: 70px; */
  transition: width 0.5s;
}

.sidebar.collapsed {
  width: 72px;
  transition: width 0.5s;
}

.sidebar a {
  /* justify-content: center; */
  /* display: block !important; */
  /* align-items: flex-start; */
  min-height: 50px;
}

.sidebar a span {
  transition: opacity 0.5s linear;
  /* transform: translate(0); */
  opacity: 1;
  margin-left: 35px;
  white-space: nowrap
}

.sidebar .submenu a span {
  margin-left: 0;
}

.sidebar.collapsed a span {
  transition: opacity 0.5s linear;
  /* transform: translate(9999px); */
  position: absolute;
  width: 100%;
  opacity: 0;
  /* display: none; */
}

.sidebar a svg {
  position: absolute;
}

.sidebar.collapsed a svg {
  margin-right: 0 !important;
}

.sidebar a p {
  display: flex;
  margin-left: auto;
  margin-bottom: 0;
  align-items: center;
  margin-right: 1rem;
}

.sidebar.collapsed a p {
  display: none;
}

.sidebar-toggle-btn {
  margin-top: auto;
}

.user-icon {
  width: 30px;
  height: 30px;
  /* background-color: #C2185B; */
  /* background-color: #FF1744; */
  background-color: #FF5252;
  color: #FFFFFF;
  position: relative;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  display: flex;
  border-radius: 15px;
  border: 2px solid #FFFFFF;
}


/* Table  */

table thead {
  background-color: rgb(22, 23, 27) !important;
  /* color: #FFFFFF; */
}

/* table thead tr {
  border-bottom: 1px solid #666666;
} */

table tbody tr {
  /* border-top: 1px solid rgb(222, 226, 230); */
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  /* --bs-alert-border-color: #ffecb5 !important; */
}

table tbody tr:hover {
  border-top: 1px solid #ffecb5;
  border-bottom: 1px solid #ffecb5;
}

table tbody tr.active_table_row {
  /* border-top:
    1px solid #ffecb5;
  background-color: #ffecb5;
  border-bottom: 1px solid #ffecb5; */
  /* border-bottom: 1px solid red; */
}

table th,
table td {
  vertical-align: middle;
  padding: 1rem !important;
  /* max-width: 200px;
  word-wrap: break-word; */
  max-width: 300px;
  min-width: 120px;
  position: relative;
}


table th.auto-min-width,
table td.auto-min-width {
  min-width: auto;
}

table th.min-width500,
table td.min-width500 {
  /* width: 500px */
  min-width: 400px;
}

table thead tr th {
  background-color: rgb(22, 23, 27) !important;
  color: #FFFFFF !important;
}

table thead tr th:first-of-type {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

table thead tr th:last-of-type {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

/* table tbody tr td:first-of-type {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

table tbody tr td:last-of-type {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
} */

table thead.fixed-head {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
}


.table-hover>tbody>tr:hover>* {
  /* background-color: #fff3cd; */
  color: #664d03 !important;
  --bs-table-accent-bg: #fff3cd !important;
  --bs-table-bg-state: #fff3cd !important;
}


.table-row-icon {
  background-color: #f1f1f1;
  color: #666666;
  padding: 0.5rem;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}

/* div {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

div::-webkit-scrollbar {
  display: none;
} */
.nav-tabs {
  /* border-width: 2px; */
}

.nav-tabs .nav-link {
  border: 0 !important;
  border-bottom: 2px solid transparent;
  color: #999999;
  font-weight: 600;
  padding-bottom: 15px;
  padding-top: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:focus-visible,
.nav-tabs .nav-link:hover {
  border: 0;
  border-bottom: 2px solid #000000 !important;
  color: #000000 !important;
  outline: 0;
}

.nav-tabs .nav-link.active {
  font-weight: 600;
}

.viewMoreText {
  color: #0d6efd;
  cursor: pointer;
}


/* Common */

/* .border-bottom {
  border-bottom: 1px solid #dee2e6;
} */


.border-bottom {
  border-bottom: "1px solid #dee2e6",
}

.border-top {
  border-top: 1px solid #dee2e6;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.reset-link {
  color: #111;
  text-decoration: none;
}

.reset-link-secondary {
  color: #fff;
  text-decoration: none;
}

.sticky-header {
  background: rgb(22, 23, 27) !important;
  color: #FFFFFF;
  border-radius: 7px;
  position: sticky;
  top: 0;
}

.sticky-header span {
  font-weight: 700;
  z-index: 2;
}

.sticky-tab .nav-tabs {
  position: sticky;
  top: 0;
  z-index: 2;
  padding-top: 1rem;
  background-color: #FFFFFF;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 0 !important;
}

.sticky-tab .tab-content {
  /* padding: 0 1.5rem 1.5rem 1.5rem; */
  padding: 1.5rem;
}


.sticky-tab .sticky-toolbar {
  position: sticky;
  top: 46px;
  background-color: #FFFFFF;
  padding: 1rem 0;
  z-index: 1;
}

.sticky-tab .sticky-toolbar .form-switch {
  margin-bottom: 0;
}

.sticky-tab .header-actions-wrap {
  position: sticky;
  top: 0;
  z-index: 3;
}

.sticky-tab .header-actions-wrap .btn-wrap {
  position: absolute;
  top: 0.75rem;
  right: 1.5rem;
}

.custom-table-row-head {
  /* color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; */
}

.custom-table-row-group {
  color: #383d41;
  /* background-color: #e2e3e5; */
  border-color: #d6d8db;
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-table-row {
  background-color: #FFFFFF;
}

.custom-table-row:hover {
  background-color: #fff3cd !important;
  color: #664d03 !important;
  /* --bs-table-accent-bg: #fff3cd !important; */
}

.cursor-pointer {
  cursor: pointer;
}

.tile-icon {
  padding: 0.5rem !important;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.tile-icon img {
  width: 18px;
}

/* Timeline */

.timeline {
  list-style-type: none;
  /* position: relative; */
  position: absolute;
  /* height: 100%; */
  padding-right: 1rem;
  /* background-color: #f00; */
  padding: 0;

  /* background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 3px;
  background-repeat: repeat-y; */
}

.timeline:before {
  content: " ";
  /* background: #d4d9df; */

  border-left: 2px dashed #999999;
  display: inline-block;
  position: absolute;
  /* left: 10px; */
  left: 20%;
  width: 2px;
  height: calc(100% - 20px);
  z-index: 2;
}


ul.timeline>li .dateLabel {
  position: sticky;
  width: 20%;
  max-height: 80px;
  text-align: center;
  background-color: #16171b;
  /* border: 1px solid #999999; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  padding: 15px;
}

ul.timeline>li div {
  position: relative;
  width: 80%;
}



ul.timeline>li div:first-of-type::before {
  content: " ";
  background: #ffdedb;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #ffdedb;
  left: -8px;
  top: 0px;
  width: 16px;
  height: 16px;
  z-index: 2;
}

ul.timeline>li div:first-of-type::after {
  content: " ";
  background: #ed6a5e;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #ed6a5e;
  left: -4px;
  top: 4px;
  width: 8px;
  height: 8px;
  z-index: 2;
}

ul.timeline>li div div::before {
  display: none !important;
}

ul.timeline>li div div::after {
  display: none !important;
}

ul.timeline>li div p.timeline-item {
  border: 1px solid #999999;
  margin-bottom: 0;
}

ul.timeline>li div p.timeline-item p {
  margin-bottom: 0;
}

.timeline-label-2 {
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #f1f1f1;
  height: 26px;
  display: flex;
  align-items: center;
}


/* Timeline 2 */

.timeline-2 {
  list-style-type: none;
  /* position: relative; */
  position: absolute;
  /* height: 100%; */
  padding-right: 1rem;
  /* background-color: #f00; */
  padding: 0;

  /* background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 3px;
  background-repeat: repeat-y; */
}

.timeline-2:before {
  content: " ";
  /* background: #d4d9df; */

  border-left: 2px dashed #999999;
  display: inline-block;
  position: absolute;
  /* left: 10px; */
  top: 6rem;
  left: 7px;
  width: 2px;
  height: calc(100% - 20px);
  z-index: 2;
  display: none;
}


ul.timeline-2>li .dateLabel {
  position: sticky;
  width: 100%;
  max-height: 80px;
  text-align: center;
  background-color: #FFFFFF;
  /* border: 1px solid #999999; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  padding: 15px;
  z-index: 1;
}

ul.timeline-2>li div {
  position: relative;
  /* width: 80%; */
}



ul.timeline-2>li div:first-of-type::before {
  content: " ";
  background: #ffdedb;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #ffdedb;
  left: -0;
  top: 0px;
  width: 16px;
  height: 16px;
  z-index: 2;
  display: none;
}

ul.timeline-2>li div:first-of-type::after {
  content: " ";
  background: #ed6a5e;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #ed6a5e;
  left: 4px;
  top: 4px;
  width: 8px;
  height: 8px;
  z-index: 2;
  display: none;
}

ul.timeline-2>li div div::before {
  display: none !important;
}

ul.timeline-2>li div div::after {
  display: none !important;
}

ul.timeline-2>li div .timeline-item {
  margin-bottom: 0;
}

/* ul.timeline-2>li div .timeline-item-content-wrap { */
ul.timeline-2 div .timeline-item-content-wrap {
  border: 1px solid #f1f1f1;
  margin-bottom: 0;
  background: #FFFFFF;
  /* box-shadow: 2px 0px 30px -10px rgba(0, 0, 0, 0.2); */
}

ul.timeline-2>li div .timeline-item-content-wrap:hover {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

ul.timeline-2>li div p.timeline-item p {
  margin-bottom: 0;
}

.timeline-2-label-2 {
  padding: 2px 10px;
  border-radius: 5px;
  background-color: #f1f1f1;
  height: 26px;
  display: flex;
  align-items: center;
}


/* Multiple toggle buttons  */

.multipleToggleButtonWrapp {
  display: flex;
  flex-direction: row;
  border-radius: 7px;
  background-color: #f1f1f1;
  overflow: hidden;
  width: -webkit-fill-available;
}

.multipleToggleButtonWrapp div {
  padding: 5px 10px;
  background-color: #f1f1f1;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.multipleToggleButtonWrapp div.active {
  background-color: #cccccc;
}

.tile-label {
  font-size: 12px;
}




/* Modal */

.modal-label,
.control-label {
  color: #999999;
}

.modal-label-sm {
  font-size: 10px;
}

.modal-text {
  font-weight: 600;
  word-wrap: break-word;
}

.modal-row-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.5rem;
}

.modal-row-item .alert {
  /* font-size: 10px; */
  word-wrap: break-word;
  max-width: 100%;
}

.modal {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
}

.modal-no-backdrop {
  background-color: transparent;
  backdrop-filter: none;
  width: auto !important;
  right: 0;
  left: auto !important;
  padding-left: 1rem;
}

.modal-body .sticky-top {
  position: sticky;
  top: -12px;
  background: #ffffff;
}

.modal-content {
  height: 100%;
}

.modal-auto-height .modal-content {
  height: auto;
}

.modal-right {
  right: 1rem;
  float: right;
}

.modal-full-height {
  height: calc(100vh - 2rem) !important;
  margin: 0 !important;
  top: 1rem;
}

.modal-right.modal-lg,
.modal-right.modal-xl {
  width: 800px;
}

.modal-right.modal-md {
  width: 500px;
}

.modal-right.modal-sm {
  width: 300px;
}

.modal.minimized {
  height: auto;
  max-height: 100px;
  bottom: 0;
  margin-top: calc(100vh - 8rem);
}

.modal.minimized .modal-dialog {
  height: auto;
  align-items: flex-end;
}

.modal.minimized .modal-body {
  display: none;
}

.sticky-title-section {
  background: #FFFFFF;
  position: sticky;
  z-index: 2;
}

.sticky-top-0 {
  position: sticky;
  top: -0;
  background: #FFFFFF;
}


/* Text */

.text-left {
  text-align: right;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}



/* Pagination */

.tab-pane .pagination-wrap {
  position: absolute;
  top: 1rem;
  right: 1rem;
  align-items: center;
}

.tab-pane .pagination-wrap.pagination-wrap-relative {
  position: relative;
  top: 0;
  right: 0;
  align-items: center;
}


.pagination li a.page-link {
  border: 0;
  border-radius: 50px !important;
  width: 30px;
  height: 30px !important;
  background: #f1f1f1;
}



/* Tiile card item */

.card .btn-wrap {
  display: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.card:hover .btn-wrap {
  display: flex;
}

.card .btn-wrap button {
  border-radius: 50px !important;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card:hover .card-body {
  outline: 2px solid rgb(22, 23, 27) !important;
  /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
}

.card .card-body {
  background-color: #f1f1f1;
}


.ti-card:hover {
  border-color: #000000;
}

/* Filter */

.filter-item {
  background-color: #f1f1f1;
}

.inline-filter-wrap {
  top: 0;
  right: 0;
  /* left: 100%; */
  position: absolute;
  /* background: rgba(255, 255, 255, 0.6); */
  width: 50px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  right: 0;
  top: 0;
  margin: 0 !important;
}

.inline-filter {
  position: absolute;
  /* background: rgba(255, 255, 255, 0.6); */
  width: 50px;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  right: 0;
  top: 0;
  margin: auto 0 !important;
}

.inline-filter span {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #FFFFFF;
  /* background: rgba(255, 255, 255, 0.6); */
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.inline-filter span:first-of-type:hover {
  color: #388E3C;
}

.inline-filter span:last-of-type:hover {
  color: #F44336;
}

.filter-tag:hover {
  transition: .3s;
}

.filter-tag:hover {
  background-color: #d7dae6;
}




/* Background colors */

.bg-grey {
  background-color: #F6F7FB;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-purlpe,
.bg-a,
.bg-b,
.bg-c {
  background-color: #8E24AA;
}

.bg-orange {
  background-color: #FF5722;
}

.bg-red {
  background-color: #F44336;
}

.bg-pink-1 {
  background-color: #FF4081;
}

.bg-pink-2,
.bg-d,
.bg-e,
.bg-f {
  background-color: #C51162;
}

.bg-amber,
.bg-g,
.bg-h,
.bg-i {
  background-color: #FFAB00;
}

.bg-green-1,
.bg-m,
.bg-n,
.bg-p {
  background-color: #00BFA5;
}

.bg-green-2,
.bg-s,
.bg-t,
.bg-u {
  background-color: #388E3C;
}

.bg-blue-1,
.bg-j {
  background-color: #1565C0;
}

/* Select compponent */

.css-13cymwt-control,
.css-t3ipsp-control {
  min-height: initial !important;
}

.css-1fdsijx-ValueContainer {
  padding: 1px 10px !important;
}

.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer {
  padding: 0 5px !important;
}

.react-flow__handle {
  opacity: 0;
}

.flow {
  flex-grow: 1;
  position: relative;
  height: 100%;
}

.react-flow .handleRight {
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  /* opacity: 0; */
}

.react-flow .handleLeft {
  top: 30%;
  left: 52%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  /* opacity: 0; */
}

.react-flow-sidebar .nav-tabs {
  position: sticky;
  top: 0;
  background: #FFFFFF;
  z-index: 2;
}

.progress {
  height: 20px !important;
}

/* Home page */
.home-icon-tile {
  transition: .3s;
  border: 0;
}

.home-icon-tile:hover {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.logo-pdf-header {
  width: 120px;

}

input#datetime {
  position: relative;
  overflow: hidden;
}

input#datetime::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(12)
}



/* form control */

.form-control-style {
  border-color: hsl(0deg 0% 80%) !important;
  background-image: none !important;
}

.list-item {
  min-height: 50px;
}

.list-item div.button_wrap {
  display: none;
}

.list-item:hover div.button_wrap {
  display: flex;
}

.tag-item .search {
  display: none;
}

.tag-item .tag {
  background-color: #e6e6e6;
  border: 1px solid #e9e9e9;
  padding: 2px 6px 2px 2px;
  border-radius: 1px;
  display: flex;
  color: #000000;
  flex-direction: row;
}

.tag-item .tag-remove {
  color: #a0a0a0;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 8px;
  margin-bottom: 1.5px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  border: 1px solid #e5d4d4;
  border-radius: 4px;

}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  content: "\E5CF";
  border-left: solid 1px lightgray;
  padding-left: 4px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top {
  display: none;
}

.dropdown .dropdown-content {
  overflow: auto;
  max-height: 50vh;
  overflow: scroll;
  z-index: 3 !important;
}

.cykit,
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}


.no-caret .dropdown-toggle::after {
   display: none;
}